import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {User} from "@/areas/users/model/data/User";
import {Entity} from "@/areas/entities/model/data/dtos/entity";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";

export class UserAssessmentExtensions {
    static parseText(someString: string, delegate: DelegateDto, entity: Entity, user?: User): string {
        let returnString = someString

        if (delegate.delegateType == DelegateType.Individual) {
            returnString = someString.replace("<first_name>", user?.firstName ?? 'They')

            returnString = returnString.replace("<name>", user?.firstName ?? 'They')
        }

        if (delegate.delegateType == DelegateType.Team) {
            returnString = someString.replace("<first_name>", delegate.displayName)

            returnString = returnString.replace("<name>", delegate.displayName)
        }

        return returnString.replace("<organisation>", entity.displayName!)
    }
}