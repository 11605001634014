export const graph_red = '#841F27'
export const graph_green = '#7D983C'
export const sectionIndex = ['A', 'B', 'C', 'D', 'E', 'F'];
export const section_description = ['My Space', 'My Team', 'Our Culture', 'Our Results', 'Our Growth','Our Purpose']
export const default_chart_colors = ['#5C81D0','#95C6CF','#FFE285','#BA616E','#C5E089','#ADB4DE']
export const colors = {
    secondary: graph_green,
    primary: '#698C9A',
    reddish: '#831F27',
}

export const numberWords: any = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: 'eleven',
    12: 'twelve',
    13: 'thirteen',
    14: 'fourteen',
    15: 'fifteen',
    16: 'sixteen',
    17: 'seventeen',
    18: 'eighteen',
    19: 'nineteen',
    20: 'twenty',
};

export const graph_palette = [
    '#9A0000',
    '#54687B',
    '#7D983C',
    '#ffce34',  //color change
    '#4164af',
    '#688B9A',
    '#C5CDD8',
    '#841F27',
    '#3762C6',
    '#FFCC33',
    '#9FCE1D',
  ]
  
export const graph_label_color = [
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#FFFFFF',
    '#FFFFFF',
    '#000000',
    '#000000',
  ]
  
export const maxRating = 5
export const cpr = { text: '©', style: ['superscript'], fontFeatures: ['sups'] };
export const spacing = {
    section: 15,
    subsection: 10,
}
export const soft_hyphen = '\u2011'
interface BehaviourLeadingQuestions {
  [key: string]: {
    shortStatement: string;
    behaviourChanges: string[];
  };
}
export const behaviour_leading_questions:BehaviourLeadingQuestions = {
  my_space: {
    shortStatement: `Individual belonging and growth in the organisation`,
    behaviourChanges: [
      `Have I clarified roles, responsibilities and my expectations for how I want each of my team members to perform?`,
      `I am approachable, making an effort to listen and ask incisive questions to understand the needs and fears of each of my team members?`,
      `I make consistent and dedicated time for one-on-one sessions with each of my team members to review their performance and provide honest feedback and guidance?`,
      `What additional resources and support do I provide my team members with to enable them to perform to my expectations?`,
      `What new opportunities, tasks and projects can I delegate that will grow my team members skills and experience?`
    ]
  },
  my_team: {
    shortStatement: `Levels of communication and trust within and between teams`,
    behaviourChanges: [
      `Have you clarified the purpose, mandate and performance expectations for your team?`,
      `Do I consistently engage all of my team members to keep them informed and ensure their efforts are in line with the team mandate and performance targets?`,
      `Do I ensure clear lines of communication and encourage collaboration with other teams?`,
      `Do I value and encourage all of my team members beliefs and contributions – and how do I show this?`,
      `Do I address conflict situations timeously and respectfully in a safe space, working to an outcome that benefits all of my team members?`,
      `Do I make the effort to recognise and praise good work done by all of my team members?`
    ]
  },
  our_culture: {
    shortStatement: `Alignment to shared values and behaviours for the organisation`,
    behaviourChanges: [
      `Do I regularly engage team members to affirm the expected behaviours that uphold the organisation’s values and culture?`,
      "Do I display values-based leadership by modelling the behaviours that uphold the culture?",
      `What behaviours do the team and I need to change that undermine the culture?`,
      `Do I reflect on new challenges and opportunities through the lens of the organisation’s values?`
    ]
  },
  our_results: {
    shortStatement: `Drive to take ownership and deliver on performance targets`,
    behaviourChanges: [
      `What new practices do I need to adopt to build resilience through tough times?`,
      `Which behaviours are hindering the team and I from achieving our performance targets?`,
      `Have I clarified, and do I model, my expectations on work ethic, professional standards and responsiveness with my team?`,
      `Am I consistent and disciplined in sticking to daily communication and meeting routines?`,
      `What practices need to change in how I hold my team accountable to deliver on performance targets?`,
      `Do I enhance accountability by encouraging my team members to make their own decisions appropriate to their role?`
    ],
  },
  our_growth: {
    shortStatement: `Capacity to challenge, improve and innovate`,
    behaviourChanges: [
      `How much time do I commit to reading latest trends in my market and industry – and then sharing that information with my team to encourage fresh thinking?`,
      `Have I built a safe space for my team that encourages them to challenge the status quo and raise new ideas?`,
      `What needs to change in how the team and I take advantage of new opportunities that will grow the business?`,
      `How do I lead my team through times of change – and what can I do differently to address their fears and uncertainty?`,
      `Do the team and I continually improve by learning lessons from mistakes and previous projects?`,
    ]
  },
  our_purpose: {
    shortStatement: `Alignment to the big picture: strategy and focus`,
    behaviourChanges: [
      "Do I need to clarify the underlying purpose and long-term vision of the organisation with my team?",
      `How can I embed and communicate the relevance of the organisation’s purpose and vision to my team’s mandate and performance targets?`,
      `Am I responsive in realigning my team’s efforts to support the strategy and financial performance of the organisation?`,
      `Do I keep my team updated on the future direction of the organisation – and what is needed of them to support impending changes in the market?`
    ]
  }
}