//@ts-ignore
import * as d3 from 'd3'

import {IChartGroup, IChartSegment, ITriangle, ITrianglePoints} from './types'
import {ReportAssessment} from "@/areas/reports/models/data/ReportAssessment";
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {contrastingColor} from "@/components/Atomic/BAtoms/Reports/utils";

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWZYZ';
const grid_grey = '#000000';
const sectionIndex = ['A', 'B', 'C', 'D', 'E', 'F'];

/**
 * @returns null or a svg string representing the 1viewChart
 */
export const drawLeadership360 = (reportData: ReportAssessment, box: number, scale: number, containerSelector: string) => {

    let size = box * scale;
    let width = size;
    let height = size;
    let lineLength = size * 0.45;

    let group_degree_offset = 120;

    const containerElement = document.createElement('div');
    containerElement.setAttribute('id', 'container');
    const chartElement = document.createElement('div');
    chartElement.setAttribute('id', 'chart');
    containerElement.appendChild(chartElement);

    document.body.appendChild(containerElement)

    const container = d3.select("#chart");
    container.html = ''

    let svg = container.append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("version", "1.1")
        .attr("viewBox", `0 0 ${width} ${height}`);

    const font = svg.append("font")
        .attr("id", "Roboto")

    const face = font.append("font-face")
        .attr("font-family", "Roboto")
        .attr("font-weight", "normal")
        .attr("font-style", "normal")

    face.append("font-face-src");

    svg.append("font")
        .attr("id", "Roboto Bold")
        .append("font-face")
        .attr("font-family", "Roboto")
        .attr("font-style", "bold")
        .append("font-face-src")
        .append("font-face-uri")

    let layers: any = {
        base: null,
        grid: null,
        sections: [],
        scores: [],
        labels: [],
        personalScores: []
    };

    /**
     * For each data segment (var data)
     *  var arcRads = toRads(360 / data.length)
     *
     *  create svg+g element. (var poly)
     *      + add base poly with line length of max length
     *        background fill (var fillColor rgba)
     *
     *
     *  poly can be described as a triangle with origin 0,0
     *  line  (var baseLine) of the poly is at 0 degrees with offset degress (var degreesOffset)
     *  the angle alpha at origin 0,0 is arcRads.
     *
     *  sin(alpha) / a = sin(beta) / b = sin(y) / c
     *
     *  sin(arcRads) / a = sin()
     */

    /**
     * Basic function to convert degrees to radians.
     * @param degrees
     * @returns
     */
    const toRad = (degrees: number) => {
        return degrees * (Math.PI / 180);
    }


    // use law of sines to get factor
    const getLengthFactor = (angle = 100, length = 5) => {
        return Math.sin(toRad(angle)) / length
    }


    let segments: IChartSegment[] = [];
    let groups: IChartGroup[] = [];

    // indicates the angle from zero baseline where the segment
    // starts rendering.  These will be in increments equal
    // to the group angle size divided by the number of sub segments.
    let segment_angle = undefined;
    let segment_angleRad = undefined;
    let segment_angleOut = undefined;
    let segment_angleOutRad = undefined;
    // let segment_offset = 3;
    let segment_max = 10;

    /**
     * draws the scores
     */
    const drawScores = () => {
        if (layers.scores) {
            layers.scores.map(((scoreLayer: any) => scoreLayer.remove()));
        }

        layers.scores = []

        let segmentIndex = 0

        // data.qualitiesMap.forEach((qualityScore: any, qIdx: number) => {
        reportData.assessDomains.forEach((reportDomain: ReportDomain, qIdx: number) => {
            //quality represents a group
            let color = reportDomain.domain.colour

            let layer = svg.append('g')
                .attr('id', `delegate-one-view-chart-${reportData.guid}-${qIdx}`);

            let personalLayer = svg.append('g').attr('id', `delegate-one-view-chart-${reportData.guid}-${qIdx}-personal`);


            let group_angle = 360 / reportData.assessDomains.length;

            const totalQuestions = reportDomain.reportQuestions.length

            segment_angle = (group_angle / totalQuestions);
            segment_angleRad = Math.PI * segment_angle / 180;
            segment_angleOut = (180 - segment_angle) / 2;
            segment_angleOutRad = Math.PI * segment_angleOut / 180;

            /**
             * Chart quadrants can
             * be represented like below
             *           90
             *           |
             *        1  |   0
             * 180 ------|------- 0/360
             *        2  |   3
             *           |
             *          270
             */
            let quadrant = 0; //values can be 0, 1, 2, 3


            let textRotation = 0;
            let group_angle_calculated = (group_angle * qIdx) + group_degree_offset;
            if (group_angle_calculated > 360) {
                group_angle_calculated -= 360
            }

            if (group_angle_calculated > 0 && group_angle_calculated <= 90) {
                quadrant = 0;
            }

            if (group_angle_calculated > 90 && group_angle_calculated <= 180) {
                quadrant = 1;
            }

            if (group_angle_calculated > 180 && group_angle_calculated <= 270) {
                quadrant = 2;
            }

            if (group_angle_calculated > 270 && group_angle_calculated <= 360) {
                quadrant = 3;
            }

            switch (group_angle_calculated) {
                case 0:
                case 180:
                case 360: {
                    textRotation = 300;
                    break;
                }
                case 120:
                case 300: {
                    textRotation = 60;
                    break;
                }
            }


            const getMaxReductionFactor = () => {
                return 0.867 //should be calculated figure
            }


            //add behaviour scores
            for (let bIndex = 0; bIndex < totalQuestions; bIndex++) {

                /**
                 *
                 * @param angle - the current actual angle in scope of 360 degree
                 * @returns
                 */
                const getReductionFactor = (): number => {
                    //angle is going to be the angle
                    //max reduction factor at peak relative to the center point. //this is for 5 points
                    //the lower the number, the shorter the length will be that is multiplied
                    //the higher the number the longer the length will be that us multiplied
                    let factor = getMaxReductionFactor();

                    if (totalQuestions === 3) {
                        switch (bIndex) {
                            case 0: {
                                return 1;
                            }
                            case 1: {
                                return 0.88
                            }
                            case 2: {
                                return 0.88;
                            }
                        }
                    }

                    if (totalQuestions === 4) {
                        switch (bIndex) {
                            case 0: {
                                return 1;
                            }
                            case 1: {
                                return 0.89
                            }
                            case 2: {
                                return 0.89
                            }
                            case 3: {
                                return 0.92
                            }
                        }
                    }

                    if (totalQuestions === 5) {
                        switch (bIndex) {
                            case 0: {
                                return 0.980
                            }
                            case 1: {
                                return 0.908
                            }
                            case 2: {
                                return 0.867
                            }
                            case 3: {
                                return 0.867
                            }
                            case 4: {
                                return 0.908
                            }
                        }
                    }


                    return factor;
                }

                const getReductionFactorPersonal = (): number => {
                    //angle is going to be the angle
                    //max reduction factor at peak relative to the center point. //this is for 5 points
                    //the lower the number, the shorter the length will be that is multiplied
                    //the higher the number the longer the length will be that us multiplied
                    let factor = getMaxReductionFactor();

                    //not quite sure how to create a formula for these options
                    // implementing a 3 / 4 and 5 question set options.
                    // not optimal but works.
                    if (totalQuestions === 3) {
                        switch (bIndex) {
                            case 0: {
                                return 0.92;
                            }
                            case 1: {
                                return 0.867
                            }
                            case 2: {
                                return 0.92;
                            }
                        }
                    }

                    if (totalQuestions === 4) {
                        switch (bIndex) {
                            case 0: {
                                return 1;
                            }
                            case 1: {
                                return 0.89
                            }
                            case 2: {
                                return 0.89
                            }
                            case 3: {
                                return 0.92
                            }
                        }
                    }

                    if (totalQuestions === 5) {
                        switch (bIndex) {
                            case 0: {
                                return 0.948
                            }
                            case 1: {
                                return 0.884
                            }
                            case 2: {
                                return 0.867
                            }
                            case 3: {
                                return 0.884
                            }
                            case 4: {
                                return 0.948
                            }
                        }
                    }


                    return factor;
                }

                let behaviour = reportDomain.reportQuestions[bIndex]

                //angle of the personal score rating
                let personalScoreAngle = (segmentIndex * segment_angle) + group_degree_offset + (segment_angle / 2);

                // add score avg from others
                // the length represents the values of LC and LB
                // for each iteration the lineLength changes depending on
                // the angle.  We must calculate the line length for each leg for
                // the triangle.

                const avgOthers = behaviour.resultsOtherAverage

                let length = (lineLength / segment_max) * avgOthers;

                // add personal score
                let personalScoreLength = (lineLength / segment_max) * (behaviour.resultsMine ?? 0)

                // // the angle in degrees between the baseline of the triangle
                // // and the opposite line.
                // let alpha = segment_angle * (bIndex + 1) + group_degree_offset;


                /**
                 *           beta
                 *          / \
                 *         /    \
                 *    LC  /       \   LA
                 *       /          \
                 *      /             \
                 *     /________________\  gamma
                 * alpha       LB
                 *
                 *  alpha = segment angle
                 *
                 *
                 */

                let triangle: ITriangle = {
                    alpha: segment_angle, // 5 questions == 12 deg 3 questions === 20 deg etc. //stays static
                    lineA: 0,  //length will vary depending on LC / LB
                    beta: 0, // varies depending on previous item
                    lineB: 0,
                    gamma: 0,
                    lineC: 0,
                }


                if (bIndex === 0) {
                    triangle.gamma = 60;
                    triangle.lineB = length;
                } else if (bIndex === totalQuestions) {
                    triangle.gamma = 180 - segments[bIndex - 1].triangle.beta;
                    triangle.lineC = length;
                } else {
                    triangle.gamma = 180 - segments[bIndex - 1].triangle.beta;
                    triangle.lineB = length * getReductionFactor();
                }

                triangle.beta = 180 - triangle.alpha - triangle.gamma;


                //  sine(alpha) / LA = Sin(beta) / LB

                // we are going to use the law of sines to
                // first solve our triangle
                // Sine (alpha) / a ==  Sine(gamma) / c == Sine(beta) / b
                // we can also use law of cosines to solve angles.
                // c^2 = a^2 + b^2 - 2.a.b.cos(C)
                // LC^2 = (LA^2 + LB^2) - (2 * LA * LB * cosine(gamma))
                let factor = getLengthFactor(triangle.beta, triangle.lineB);

                let relativeAngle = (segmentIndex * segment_angle);

                let currentAngle = (relativeAngle) + group_degree_offset;

                if (currentAngle > 360) {
                    currentAngle = currentAngle - 360;
                    personalScoreAngle = personalScoreAngle - 360;
                }


                triangle.lineC = Math.sin(toRad(triangle.gamma)) / factor;
                triangle.lineA = Math.sin(toRad(triangle.alpha)) / factor;


                let personalTriangle: ITriangle = {
                    alpha: segment_angle / 2,
                    lineA: 0,  //length will vary depending on LC / LB
                    beta: 0, // varies depending on previous item
                    lineB: personalScoreLength * getReductionFactorPersonal(),
                    gamma: 0,
                    lineC: personalScoreLength * getReductionFactorPersonal(),
                };

                if (bIndex === 0) {
                    personalTriangle.gamma = 60;
                } else {
                    personalTriangle.gamma = 180 - segments[bIndex - 1].personalTriangle.beta;
                }

                personalTriangle.beta = 180 - triangle.alpha - triangle.gamma;

                //setup our triangle points
                let points: ITrianglePoints = {
                    alpha: {
                        x: 0,
                        y: 0,
                    },
                    beta: {
                        x: Math.cos(toRad(currentAngle)) * triangle.lineB,
                        y: Math.sin(toRad(currentAngle)) * triangle.lineB,
                    },
                    gamma: {
                        x: Math.cos(toRad(currentAngle + segment_angle)) * triangle.lineC,
                        y: Math.sin(toRad(currentAngle + segment_angle)) * triangle.lineC,
                    },
                    center: {
                        x: Math.cos(toRad(currentAngle + (segment_angle / 2))) * triangle.lineC / 2,
                        y: Math.sin(toRad(currentAngle + (segment_angle / 2))) * triangle.lineC / 2,
                    }
                }

                let pointsPersonal = {
                    alpha: {
                        x: Math.cos(toRad(personalScoreAngle)) * personalTriangle.lineB,
                        y: Math.cos(toRad(personalScoreAngle)) * personalTriangle.lineB,
                    },
                    beta: {
                        x: Math.cos(toRad(personalScoreAngle)) * personalTriangle.lineC,
                        y: Math.sin(toRad(personalScoreAngle)) * personalTriangle.lineC,
                    }
                }

                layer
                    .append('polygon')
                    .attr('id', `${qIdx}-${bIndex}`)
                    .attr('points', `${points.alpha.x},${points.alpha.y} ${points.gamma.x},${points.gamma.y} ${points.beta.x},${points.beta.y}`)
                    .attr('stroke', grid_grey)
                    .attr('stroke-linecap', 'round')
                    .attr('fill', color)
                    .attr('stroke-width', parseFloat(`${0.5 * scale}`).toFixed(1));

                //Personal Score Circle
                if (reportData.delegate.delegateType == DelegateType.Individual && personalScoreLength != 0 ) {
                    personalLayer.append('circle')
                        .attr('r', scale,)
                        .attr('cx', pointsPersonal.beta.x)
                        .attr('cy', pointsPersonal.beta.y)
                        .attr('stroke', 'blue')
                        .attr('stroke-width', 7 * scale)
                }

                segments.push({
                    title: `${alphabet.charAt(qIdx)}${bIndex + 1}`,
                    behaviour,
                    description: "",
                    points,
                    behaviourIndex: bIndex + 1,
                    reductionFactor: getReductionFactor(),
                    reductionFactorPersonal: getReductionFactorPersonal(),
                    triangle,
                    color,
                    textColor: 'white',
                    pointsPersonal,
                    centerLineAngle: personalScoreAngle,
                    textRotation,
                    personalTriangle,
                });

                segmentIndex += 1;
            }


            layer.attr(
                'transform', `translate(${width / 2},${height / 2})`
            );

            personalLayer.attr(
                'transform', `translate(${width / 2},${height / 2})`
            );

            const allDomainResults = reportDomain
                .reportQuestions
                .flatMap(rq => rq.resultsOther)

            const total = allDomainResults.reduce((acc, value) => acc + value, 0);

            const avg = Math.round(total / allDomainResults.length * 10) / 10

            const group_label = {
                title: `${sectionIndex[qIdx]}. ${reportDomain.domain.name}`,
                score: avg,
                groupCenterLine: (group_angle * qIdx) + (group_angle / 2) + group_degree_offset,
                textRotation
            }

            if (group_label.groupCenterLine > 360) {
                group_label.groupCenterLine = group_label.groupCenterLine - 360;
            }

            groups.push(group_label)


            layers.scores.push(layer);
            layers.scores.push(personalLayer);

        });

    }

    const drawGrid = (groups = 6) => {

        let group_angle = undefined;
        let group_angleRad = undefined;
        let group_angleOut = undefined;
        let group_angleOutRad = undefined;
        let group_max = 10;
        let group_line_color = '#7D983C'

        if (layers.base) {
            layers.base.remove();
        }

        layers.base = svg.append('g')
            .attr('id', `delegate-one-view-chart-${reportData.guid}`)

        group_angle = 360 / groups;
        group_angleRad = Math.PI * group_angle / 180;
        group_angleOut = (180 - group_angle) / 2;
        group_angleOutRad = Math.PI * group_angleOut / 180;


        for (let i = 0; i < groups; i++) {

            // add a polygon
            let rotation = (group_angle * i) + group_degree_offset;

            //add increment scores
            for (let increment = group_max; increment > 0; increment--) {

                let length = (lineLength / group_max) * increment;


                let points = {
                    alpha: {
                        x: 0,
                        y: 0,
                        deg: 60,
                    },
                    beta: {
                        x: length,
                        y: 0,
                        deg: 60,
                    },
                    gamma: {
                        x: Math.cos(group_angleRad) * length,
                        y: -Math.sin(group_angleRad) * length,
                        deg: 60,
                    },
                    epsilon: {
                        x: Math.cos(group_angleRad) * ((lineLength / group_max) * (increment - 0.7)),
                        y: -Math.sin(group_angleRad) * ((lineLength / group_max) * (increment - 0.7)),
                    },
                    delta: {
                        x: ((lineLength / group_max) * (increment - 0.7)),
                        y: 0
                    }
                }

                layers.base
                    .append('polygon')
                    .attr('points', `${points.alpha.x},${points.alpha.y} ${points.beta.x},${points.beta.y} ${points.gamma.x},${points.gamma.y}`)
                    .attr('stroke', grid_grey)
                    .attr('stroke-linecap', 'round')
                    .attr('fill', 'rgba(255,255,255,0.0)')
                    .attr('stroke-width', parseFloat(`${0.5 * scale}`).toFixed(1))
                    .attr('transform', `rotate(${rotation})`)
            }


            layers.base.append('polygon')
                .attr('points', `0,0 ${lineLength},0 ${Math.cos(group_angleRad) * lineLength},${-Math.sin(group_angleRad) * lineLength}`)
                .attr('stroke-width', 2 * scale)
                .attr('stroke-linecap', 'round')
                .attr('fill', 'transparent')
                .attr('stroke', group_line_color)
                .attr('transform', `rotate(${rotation})`)

        }


        layers.base
            .attr(
                'transform',
                `translate(${width / 2},${height / 2})`
            )

    }

    const drawLabels = () => {
        //@ts-ignore
        let f = d3.format('.1f');

        let layer = svg.append('g')
            .attr('id', `delegate-one-view-chart-${reportData.guid}-labels`);

        let legend = svg.append('g')
            .attr('id', `delegate-one-view-chart-${reportData.guid}-legend`);

        let overallScoreLayer = svg.append('g')
            .attr('id', `delegate-one-view-chart-${reportData.guid}-overall-score`);

        overallScoreLayer.append('circle')
            .attr('r', 40 * scale,)
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('stroke', '#7D983C')
            .attr('stroke-width', 2 * scale)
            .attr('fill', 'white')

        overallScoreLayer.attr('transform', `translate(${width / 2},${height / 2})`)

        const allQuestionResults = reportData
            .assessDomains
            .flatMap(rd =>
                rd.reportQuestions
                    .flatMap(q => q.resultsOther))

        let overallAverage = allQuestionResults.reduce((acc, q) => acc + q, 0) / allQuestionResults.length

        overallAverage = Math.round(overallAverage * 10) / 10

        overallScoreLayer.append("text")
            .text(f(`${overallAverage}`))
            .attr('text-anchor', 'middle')
            .attr('font-family', 'Roboto')
            .attr('x', 0)
            .attr('y', 10 * scale)
            .style('font-size', 24 * scale)
            .style('font-weight', 'bold')

        segments.forEach((segment) => {

            let segmentLabelCenter = {
                alpha: {
                    x: 0,
                    y: 0,
                },
                beta: {
                    x: Math.cos(toRad(segment.centerLineAngle)) * ((lineLength / segment_max) * 9.5),
                    y: Math.sin(toRad(segment.centerLineAngle)) * ((lineLength / segment_max) * 9.5)
                }
            }


            let descriptionLabelCenter = {
                alpha: {
                    x: 0,
                    y: 0,
                },
                beta: {
                    x: Math.cos(toRad(segment.centerLineAngle)) * ((lineLength / segment_max) * 9.5),
                    y: Math.sin(toRad(segment.centerLineAngle)) * ((lineLength / segment_max) * 9.5)
                }
            }


            let labelOffsetX = 15 * scale;
            let labelOffsetY = 15 * scale;

            let offset_multiplier = 1;

            if (segment.centerLineAngle < 180) {


                segmentLabelCenter.beta.x = segmentLabelCenter.beta.x * segment.reductionFactorPersonal
                segmentLabelCenter.beta.y = segmentLabelCenter.beta.y * segment.reductionFactorPersonal

                descriptionLabelCenter.beta.x = descriptionLabelCenter.beta.x * segment.reductionFactorPersonal
                descriptionLabelCenter.beta.y = descriptionLabelCenter.beta.y * segment.reductionFactorPersonal

                labelOffsetY = -15 * scale;

            } else {

                offset_multiplier = -1;

                segmentLabelCenter.beta.x = segmentLabelCenter.beta.x * segment.reductionFactorPersonal
                segmentLabelCenter.beta.y = segmentLabelCenter.beta.y * segment.reductionFactorPersonal

                descriptionLabelCenter.beta.x = descriptionLabelCenter.beta.x * segment.reductionFactorPersonal
                descriptionLabelCenter.beta.y = descriptionLabelCenter.beta.y * segment.reductionFactorPersonal
            }

            let segment_splits = segment.description.split('\n')

            let labelFontSize = 15;

            layer.append("circle")
                .attr('r', 22 * scale)
                .attr('cx', segmentLabelCenter.beta.x + (offset_multiplier))
                .attr('cy', segmentLabelCenter.beta.y + (offset_multiplier))
                .attr('stroke', 'grey')
                .attr('stroke-width', 2 * scale)
                .attr('fill', 'grey')

            layer.append("circle")
                .attr('r', 20 * scale)
                .attr('cx', segmentLabelCenter.beta.x + (offset_multiplier))
                .attr('cy', segmentLabelCenter.beta.y + (offset_multiplier))
                .attr('stroke', segment.textColor)
                .attr('stroke-width', 2 * scale)
                .attr('fill', segment.color)

            let textSegmentLabel = layer.append("text")
                .attr('x', segmentLabelCenter.beta.x)
                .attr('y', segmentLabelCenter.beta.y + (2.5 * scale))
                .attr('text-anchor', 'middle')
                .attr('transform', `rotate(${segment.textRotation} ${segmentLabelCenter.beta.x},${segmentLabelCenter.beta.y})`)
                .style('font-size', labelFontSize * scale)
                .style('fill', segment.textColor)
                .attr('font-family', 'Roboto')
                .style('font-weight', 'bold');

            textSegmentLabel.append('svg:tspan')
                .attr('dx', 0)
                .attr('dy', 0)
                .style('fill', contrastingColor(segment.color))
                .attr('font-family', 'Roboto')
                .style('font-weight', 'bold')
                .text(segment.title);


            let txtDescription = layer.append("text")
                .text(``)
                .attr('x', descriptionLabelCenter.beta.x)
                .attr('y', descriptionLabelCenter.beta.y)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'Roboto')
                .attr('transform', `rotate(${segment.textRotation} ${descriptionLabelCenter.beta.x},${descriptionLabelCenter.beta.y})`)
                .style('font-size', labelFontSize * scale)
                .style('font-weight', 'bold')
                .style('fill', 'black');


            txtDescription.append('svg:tspan')
                .attr('dy', () => {
                    if (labelOffsetY < 0) {
                        if (segment_splits.length === 1) {
                            return 0;
                        }

                        return labelOffsetY
                    } else {
                        return 0
                    }

                })
                .attr('x', descriptionLabelCenter.beta.x)
                .style('fill', 'black')
                .attr('text-anchor', 'middle')
                .attr('font-family', 'Roboto')
                .style('font-size', labelFontSize * scale)
                .style('font-weight', 'bold')
                .text(segment_splits[0]);


            if (segment_splits.length > 1) {
                txtDescription.append('svg:tspan')
                    .attr('dy', labelOffsetY < 0 ? labelOffsetY * -1 : labelOffsetY)
                    .attr('x', descriptionLabelCenter.beta.x)
                    .style('fill', 'black')
                    .attr('text-anchor', 'middle')
                    .attr('font-family', 'Roboto')
                    .style('font-size', labelFontSize * scale)
                    .style('font-weight', 'bold')
                    .text(segment_splits[1]);
            }
        });

        groups.forEach((group) => {

            let scoreCenter = {
                beta: {
                    x: Math.cos(toRad(group.groupCenterLine)) * (lineLength + (10 * scale)),
                    y: Math.sin(toRad(group.groupCenterLine)) * (lineLength + (10 * scale))
                }
            }


            if (group.groupCenterLine > 180) {
                scoreCenter.beta.x = Math.cos(toRad(group.groupCenterLine)) * (lineLength + (10 * scale))
                scoreCenter.beta.y = Math.sin(toRad(group.groupCenterLine)) * (lineLength + (10 * scale));
            }


            let groupTitleCenter = {
                beta: {
                    x: Math.cos(toRad(group.groupCenterLine)) * (lineLength * 0.91),
                    y: Math.sin(toRad(group.groupCenterLine)) * (lineLength * 0.91)
                }
            }

            if (group.groupCenterLine < 180) {
                groupTitleCenter.beta.x = Math.cos(toRad(group.groupCenterLine)) * (lineLength * 0.94)
                groupTitleCenter.beta.y = Math.sin(toRad(group.groupCenterLine)) * (lineLength * 0.94);
            }


            layer.append("text")
                .text(group.title)
                .attr('x', groupTitleCenter.beta.x)
                .attr('y', groupTitleCenter.beta.y)
                .attr('text-anchor', 'middle')
                .attr('transform', `rotate(${group.textRotation} ${groupTitleCenter.beta.x},${groupTitleCenter.beta.y})`)
                .style('font-size', 18 * scale)
                .style('fill', 'black')
                .attr('font-family', 'Roboto')
                .style('font-weight', 'bold');

            layer.append('circle')
                .attr('r', `${25 * scale}`,)
                .attr('cx', scoreCenter.beta.x)
                .attr('cy', scoreCenter.beta.y)
                .attr('stroke', '#777170')
                .attr('stroke-width', 2 * scale)
                .attr('fill', '#777170')

            layer.append('text')
                .text(f(group.score))
                .attr('x', scoreCenter.beta.x)
                .attr('y', scoreCenter.beta.y + (5 * scale))
                .attr('text-anchor', 'middle')
                .style('font-size', 18 * scale)
                .style('fill', 'white')
                .attr('font-family', 'Roboto')
                .style('font-weight', 'bold');


        });


        if (reportData.delegate.delegateType == DelegateType.Individual) {
            const rect = {
                x: width * 0.84,
                y: (width * 0.84) + 250
            }

            legend.append('rect')
                .attr("x", rect.x)
                .attr("y", rect.y)
                .attr("width", 150 * scale)
                .attr("height", 50 * scale)
                .attr("stroke", "grey")
                .attr("stroke-width", "2")
                .attr("fill", 'rgba(255,255,255,0)')

            legend.append("circle")
                .attr('r', `${3 * scale}`,)
                .attr('cx', rect.x + (20 * scale))
                .attr('cy', rect.y + (22 * scale))
                .attr('stroke', 'blue')
                .attr('stroke-width', 3 * scale)
                .attr('fill', 'blue')


            legend.append("text")
                .text("Self Score")
                .attr('text-anchor', 'left')
                .attr("x", rect.x + (34 * scale))
                .attr("y", rect.y + (30 * scale))
                .style('font-size', Math.floor(20 * scale))
                .attr('font-family', 'Roboto')
                .style('fill', 'black')
                .style('font-weight', 'bold');
        }


        layer.attr('transform', `translate(${width / 2},${height / 2})`)
    }

    drawScores();
    drawGrid(reportData.assessDomains.length);
    drawLabels();

    const svgElement = container.select('svg');

    const svgString = new XMLSerializer().serializeToString(svgElement.node());

    document.body.removeChild(containerElement)

    return svgString;
};