import {defineStore} from 'pinia'
import {DelegateProxy} from '../proxy/delegateProxy';
import {AssessmentProxy} from "@/areas/assessments/proxy/assessmentProxy";
import {DelegateDisplayItem} from "@/areas/delegates/model/dos/DelegateDisplayItem";
import {DelegateExtensions} from "@/areas/delegates/extensions/DelegateExtensions";
import {AssessmentDto} from "@/areas/assessments/model/dtos/assessmentDto";
import {DelegateState} from "@/areas/delegates/model/enums/delegateState";
import {DelegateDto} from "@/areas/delegates/model/dtos/delegateDto";
import {AssessorProxy} from "@/areas/assessors/proxies/AssessorProxy";
import {ParticipantDo} from "@/areas/participants/models/dos/ParticipantDo";
import {DelegateType} from "@/areas/delegates/model/enums/delegateType";
import {User} from "@/areas/users/model/data/User";
import {DelegateDisplayState} from "@/areas/delegates/model/enums/DelegateDisplayState";
import {AssessorDisplayObject} from "@/areas/assessors/models/dos/AssessorDisplayObject";
import {AssessorState} from "@/areas/assessors/models/enums/AssessorState";

interface IState {
    assessmentProxy: AssessmentProxy

    delegateProxy: DelegateProxy

    assessorProxy: AssessorProxy

    delegates: DelegateDisplayItem[]

    staticDelegates: DelegateDisplayItem[]

    searchTerm: string | undefined

    entityGuid: string

    assessment: AssessmentDto | undefined

    isLoading: boolean

    error: string | undefined
}

function blankState(): IState {
    return {
        assessmentProxy: new AssessmentProxy(),

        delegateProxy: new DelegateProxy(),

        assessorProxy: new AssessorProxy(),

        delegates: [],

        staticDelegates: [],

        searchTerm: undefined,

        entityGuid: '',

        assessment: undefined,

        isLoading: true,

        error: undefined,
    }
}

export const useDelegateStore = defineStore({
    id: "delegateStore",
    state: (): IState => blankState(),
    getters: {
        getIndividualUsers: (state) => {
            return (): User[] => {
                let users: User[] = []

                for (const di of state.delegates) {
                    if (di.individual != null) {
                        users.push(di.individual)
                    }
                }

                return users
            }
        },

        getNew: (state) => {
            return state.delegates.filter(delegate => delegate.delegateDisplayState == DelegateDisplayState.New)
        },

        getExisting: (state) => {
            return state.delegates.filter(delegate => delegate.delegateDisplayState == DelegateDisplayState.Existing)
        },

        getByDisplayState: (state) => {
            return (displayState: DelegateDisplayState): DelegateDisplayItem[] => {
                return state.delegates.filter(delegate => delegate.delegateDisplayState == displayState)
            }
        }
    },
    actions: {
        async withLoading(action: () => void) {
            try {
                this.isLoading = true;
                await action();
            } catch (e) {
                console.error("Error during action execution", e);
            } finally {
                this.isLoading = false;
            }
        },

        async init(assessmentGuid: string, entityGuid: string) {
            await this.withLoading(async () => {
                this.clearState()

                const assessmentFetchResult = await this.assessmentProxy.getByGuid(entityGuid, assessmentGuid)

                if (!assessmentFetchResult.isSuccessful) {
                    this.error = 'Assessment not found'
                    this.clearState()
                    return
                }

                const delegatesFetchResult = await this.delegateProxy.list(entityGuid, assessmentGuid)

                if (!delegatesFetchResult.isSuccessful) {
                    this.error = 'Delegates could not be retrieved at this time'
                    this.clearState()
                    return
                }

                const delegateDtos = delegatesFetchResult.content!

                let reworkedDelegateDtos: DelegateDto[] = []

                for (const delegate of delegateDtos) {
                    if (delegate.entityState == DelegateState.Removed) {
                        continue
                    }

                    const delegateFetchResult = await this.delegateProxy.get(entityGuid, assessmentGuid, delegate.guid!)

                    if (!delegateFetchResult.isSuccessful) {
                        this.error = 'Could not retrieve delegate information for the delegate'
                        this.clearState()
                        continue;
                    }

                    reworkedDelegateDtos.push(delegateFetchResult.content!)
                }

                const delegates = reworkedDelegateDtos.map(del => DelegateExtensions.toDelegateDisplayItem(del))

                this.delegates = delegates
                this.staticDelegates = delegates
                this.entityGuid = entityGuid
                this.assessment = assessmentFetchResult.content
            })
        },

        async addDelegates(participantDo: ParticipantDo) {
            const addedDelegates =
                DelegateExtensions.toDelegateDisplayItemsFromParticipantDo(participantDo)

            for (const delegate of addedDelegates) {
                let existingDelegate: DelegateDisplayItem | undefined = undefined

                if (delegate.delegateType == DelegateType.Individual) {
                    existingDelegate = this.delegates.find(del =>
                        del.delegateType == DelegateType.Individual && del.individual!.guid == delegate.individual!.guid)
                } else {
                    existingDelegate = this.delegates.find(del =>
                        del.delegateType == DelegateType.Team && del.displayName == delegate.displayName)
                }

                if (!existingDelegate) {
                    //This will add the assessor by default if it is an individual
                    if (delegate.delegateType == DelegateType.Individual) {
                        const assessor: AssessorDisplayObject = {
                            employee: delegate.employee!,
                            assessorState: AssessorState.Draft,
                            assessorUpsertDto: {
                                userGuid: delegate.individual!.guid!
                            }
                        }

                        delegate.assessors.push(assessor)
                    }

                    this.staticDelegates.push(delegate)

                    this.search(this.searchTerm)
                }
            }
        },

        async deleteDelegate(delegate: DelegateDisplayItem) {
            await this.withLoading(async () => {
                this.clearError()

                if (delegate.delegateDisplayState != DelegateDisplayState.New) {
                    const deleteResult = await this.delegateProxy.delete(this.entityGuid, this.assessment!.guid!, delegate.delegateDto!.guid!)

                    if (!deleteResult.isSuccessful) {
                        this.error = "Could not delete the delegate at this time. Please refresh and try again"
                        return
                    }
                }

                this.staticDelegates = this.staticDelegates.filter(di => di.key != delegate.key)

                this.search(this.searchTerm)
            })
        },

        async updateAssessors (delegate: DelegateDisplayItem, assessors: AssessorDisplayObject[]) {
            const delegateIndex = this.staticDelegates.findIndex(del => del.key == delegate.key)

            if (delegateIndex == -1) {
                this.error = "Could not update the assessors for the delegate"
            }

            if (delegate.delegateDisplayState != DelegateDisplayState.New) {
                this.delegates[delegateIndex].delegateDisplayState = DelegateDisplayState.Edited
            }

            this.staticDelegates[delegateIndex].summary = DelegateExtensions.toSummary(assessors)

            this.staticDelegates[delegateIndex].assessors = assessors

            this.search(this.searchTerm)
        },

        search (searchString?: string) {
            if (!searchString || searchString.length === 0) {
                this.searchTerm = undefined
                this.delegates = this.staticDelegates
                return
            }

            this.searchTerm = searchString

            const searchValue = searchString.toLowerCase()

            this.delegates = this.staticDelegates.filter(delegate =>
                delegate.displayName.toLowerCase().includes(searchValue))
        },

        async save() {
            let failedList: DelegateDisplayItem[] = []

            let error: string | undefined = undefined

            const updatedDelegates = this.delegates.filter(delegate => delegate.delegateDisplayState !== DelegateDisplayState.Existing)

            if (updatedDelegates.length === 0) {
                return
            }

            await this.withLoading(async () => {
                for (const [index, delegate] of this.delegates.entries()) {
                    //Here we manage new delegates
                    if (delegate.delegateDisplayState == DelegateDisplayState.New) {

                        //If the type is team, then the delegate cannot select their own assessors, meaning that the assessors should be correct
                        if (delegate.delegateType == DelegateType.Team) {
                            const validationResult = DelegateExtensions.validateDelegate(delegate, this.assessment!)

                            if (validationResult) {
                                delegate.error = validationResult

                                failedList.push(delegate)

                                error = "Could not save some delegates, see below for errors"

                                continue
                            }
                        }

                        const createDto = DelegateExtensions.toDelegateCreateDto(delegate)

                        const createResult = await this.delegateProxy.create(this.entityGuid, this.assessment!.guid!, createDto)

                        if (!createResult.isSuccessful) {
                            delegate.error = createResult.error ?? "The delegate could not be added at this time. Please remove and try re adding the delegate"

                            error = "Could not save some delegates, see below for errors"

                            failedList.push(delegate)
                        }
                    }

                    if (delegate.delegateDisplayState == DelegateDisplayState.Edited) {
                        //If the delegate is already in a ready state and there were changes made, then we need to validate before attempting to save the delegate. Or if it is a team
                        if (delegate.delegateDto?.entityState == DelegateState.Ready || delegate.delegateType == DelegateType.Team) {
                            const validationResult = DelegateExtensions.validateDelegate(delegate, this.assessment!)

                            if (validationResult) {
                                delegate.error = validationResult

                                failedList.push(delegate)

                                error = "Could not save some delegates, see below for errors"

                                continue
                            }
                        }

                        const upsertDtos = delegate.assessors.map(as => as.assessorUpsertDto)

                        const result = await this.delegateProxy.update(this.entityGuid, this.assessment!.guid!, delegate.delegateDto!.guid!, upsertDtos)

                        if (!result.isSuccessful) {
                            delegate.error = result.error ?? "The delegate could not be updated at this time. please cancel the edit and re edit the delegate"

                            error = "Could not save some delegates, see below for errors"

                            failedList.push(delegate)
                        }
                    }
                }

                await this.init(this.assessment!.guid!, this.entityGuid)

                this.delegates.push(...failedList)
                this.error = error
            })
        },

        async sendReminder(delegate: DelegateDisplayItem) {
            const result = await this.delegateProxy.sendReminder(
                this.entityGuid!,
                this.assessment!.guid!,
                delegate.delegateDto!.guid!)

            if (!result.isSuccessful) {
                this.error = "Could not send the reminder at this time"
                console.warn(`Could not send reminder for delegate: ${result.error}`)
            }
        },

        clearError() {
            this.error = undefined
        },

        clearState() {
            this.$state = blankState()
        }
    }
});