import {colors} from "@/colors";
import {contrastingColor} from "./utils";
import {sectionIndex} from "./constants";
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";

interface IGenerateCircleSvg {
    size: number;
    color: string;
    text: any;
    font_size?: number;
    fill?: string;
    empty?: boolean;
}

export const generate_circle_svg = (args: IGenerateCircleSvg) => {
    const {
        size = 1,
        color = "#5dc612",
        text = "",
        font_size = 10,
        fill = "white",
        empty = false,
    } = args;

    const _size = 30 * size;
    return `<svg viewBox="0 0 ${_size} ${_size}" xmlns="http://www.w3.org/2000/svg" width="${_size}" height="${_size}">
      <circle cx="${_size / 2}" cy="${_size / 2}" r="${14 * size}" stroke="${
        empty ? colors["payne-grey"] : "none"
    }" fill="${empty ? "none" : color}" />
      <text x="50%" y="50%" text-anchor="middle" alignment-baseline="central" fill="${fill}" font-size="${font_size}">${text}</text>
    </svg>`;
};

export const all_in_one_svg = (
    section_id: string,
    section_color: string,
    section_title: string,
    score_all: number,
    score_self: number,
    include_self_score: boolean = true
) => {
    return `
        <svg viewBox="0 0 166 20" xmlns="http://www.w3.org/2000/svg" width="180" height="30">
        <circle cx="14" cy="14" r="10" fill="${section_color}" />
        <text x="14" y="14" text-anchor="middle" alignment-baseline="central" font="Roboto" fill="${contrastingColor(
        section_color
    )}" font-size="9">${section_id}</text>
        
        <text x="25" y="14" text-anchor="start" alignment-baseline="central" font="Roboto" fill="black" font-size="9">${section_title}</text>
        ${
        include_self_score && `<circle cx="134" cy="14" r="10" fill="${colors["wine-red"]}" />
            <text x="134" y="14" text-anchor="middle" alignment-baseline="central" font="Roboto" fill="white" font-size="9">${score_all.toFixed(
            1
        )}</text>`
    }
        
        <circle cx="154.5" cy="14" r="10" fill="${include_self_score ? colors["olive-green"] : colors["wine-red"]}" />
        <text x="154.5" y="14" text-anchor="middle" alignment-baseline="central" font="Roboto" fill="white" font-size="9">
        ${(include_self_score ? score_self : score_all).toFixed(
        1
    )}</text>
      </svg>
        `;
}

export const header_svg = (
    domain: ReportDomain,
    qualityIndex: number,
    isIndividual: boolean
) => {
    const chartColor = domain.domain.colour
    const chartTitle = domain.domain.name
    const avgMine = domain.mineDomainAverage
    const avgOther = domain.otherDomainAverage

    return `<svg viewBox="0 0 166 20" xmlns="http://www.w3.org/2000/svg" width="180" height="30">
    <rect x="5" width="170" height="30" fill="${chartColor}" />
    <text x="20" y="14" text-anchor="left" fill="${contrastingColor(
        chartColor
    )}" font-size="9px" font-weight="bold">${
        sectionIndex[qualityIndex]
    }. ${chartTitle}</text>
  ${isIndividual && `<circle cx="${avgOther ? 134 : 154.5}" cy="12" r="10" fill="${avgOther ? colors["wine-red"] : colors["olive-green"]}" />
  <text x="${avgOther ? 134 : 154.5}" y="12" text-anchor="middle" alignment-baseline="central" fill="white" font-size="9">${(avgOther).toFixed(1)}</text>`}  
    
    
  <circle cx="154.5" cy="12" r="10" fill="${isIndividual ? colors["olive-green"] : colors["wine-red"]}" />
  <text x="154.5" y="12" text-anchor="middle" alignment-baseline="central" fill="white" font-size="9">${isIndividual ? avgMine.toFixed(1) : avgOther.toFixed(1)}</text>
    
    
</svg>`;
};
