import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ReportDomain} from "@/areas/reports/models/data/ReportDomain";


export class IvolveCharts {

    static feedbackChart(welcomeScores: number[]): Promise<string> {
        return new Promise((resolve, reject) => {
            const counts: number[] = Array(10).fill(0)

            welcomeScores.forEach(num => {
                if (num >= 1 && num <= 10) {
                    counts[num - 1]++;
                }
            });

            const chartRef = document.createElement('canvas')
            chartRef.style.display = 'none';
            chartRef.width = 500

            document.body.appendChild(chartRef)

            const ctx = chartRef.getContext('2d')

            if (!ctx) {
                document.body.removeChild(chartRef);
                reject("Unable to get canvas context");
                return;
            }

            const chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    datasets: [{
                        label: 'Count',
                        backgroundColor: '#53687B',
                        data: counts
                    }]
                },
                plugins: [ChartDataLabels],
                options: {
                    responsive: false,
                    maintainAspectRatio: true,
                    devicePixelRatio: 2,
                    animation: {
                        duration: 1500,
                        onComplete: function (animation) {
                            const base64 = chart.toBase64Image('image/png');
                            document.body.removeChild(chartRef);
                            resolve(base64);
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            align: 'center',
                            display: function (context: any) {
                                return context.dataset.data[context.dataIndex] != 0
                            },
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    },
                                    color: 'white'
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            },
                            title: {
                                display: true,
                                text: 'Count',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                        x: {
                            title: {
                                display: true,
                                text: 'Rating',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        }
                    }
                }
            })
        })
    }

    static selfVsAssessor(domain: ReportDomain, isIndividual: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            const labels = domain.reportQuestions.map(rq => rq.question.key)

            const mineValue = domain.reportQuestions.find(rq => rq.resultsMine && rq.resultsMine != 0)

            const hasMine = !!(mineValue)

            const assessorSet = [{
                backgroundColor: '#9B1B1F',
                data: domain.reportQuestions.map(rq => rq.resultsOtherAverage.toFixed(1)),
                label: isIndividual ? "Assessor's Average Score" : "Average Score"
            }]

            if (hasMine && isIndividual) {
                assessorSet.push({
                    backgroundColor: '#7D983C',
                    data: domain.reportQuestions.map(rq => rq.resultsMine!.toFixed(1)),
                    label: "Self-score"
                })
            }

            const containerDiv = document.createElement('div');
            containerDiv.className = 'chart-container';
            containerDiv.style.position = 'relative';
            containerDiv.style.height = '225px'; // Set desired height
            containerDiv.style.width = '1000px'; // Set desired width

            const chartRef = document.createElement('canvas')

            containerDiv.appendChild(chartRef)

            document.body.appendChild(containerDiv);

            const ctx = chartRef.getContext('2d')

            if (!ctx) {
                document.body.removeChild(containerDiv);
                reject("Unable to get canvas context")
                return;
            }

            const chart = new Chart(ctx, {
                type: 'bar',
                plugins: [ChartDataLabels],
                data: {
                    labels: labels,
                    datasets: assessorSet
                },
                options: {
                    responsive: true,
                    devicePixelRatio: 2,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        datalabels: {
                            align: 'center',
                            display: function (context: any) {
                                return context.dataset.data[context.dataIndex] != 0
                            },
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    },
                                    color: 'white'
                                }
                            }
                        }
                    },
                    animation: {
                        onComplete: function (animation) {
                            const base64 = chart.toBase64Image('image/png');
                            document.body.removeChild(containerDiv)
                            resolve(base64);
                        },
                    },
                    scales: {
                        y: {
                            min: 0,
                            max: 10,
                            beginAtZero: true,
                            ticks: {
                                stepSize: 2
                            },
                            title: {
                                display: true,
                                text: 'Score',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                    }
                }
            })
        })
    }


    static otherRatingCount(domain: ReportDomain): Promise<string> {
        return new Promise((resolve, reject) => {
            const colours = ["#576779", "#607349", "#8ca1ad", "#d2d7df", "#aac48c", "#7a272a"]

            const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

            let dataSet: any[] = []

            domain.reportQuestions.map(rq => {
                let values: number[] = []

                labels.map(l => {
                    values.push(rq.resultsOther.filter(r => r == l).length)
                })

                dataSet.push({
                    backgroundColor: colours[dataSet.length],
                    data: values,
                    label: rq.question.key
                })
            })


            const containerDiv = document.createElement('div');
            containerDiv.className = 'chart-container';
            containerDiv.style.position = 'relative';
            containerDiv.style.height = '225px'; // Set desired height
            containerDiv.style.width = '1000px'; // Set desired width

            const chartRef = document.createElement('canvas')

            containerDiv.appendChild(chartRef)

            document.body.appendChild(containerDiv);

            const ctx = chartRef.getContext('2d')

            if (!ctx) {
                document.body.removeChild(containerDiv);
                reject("Unable to get canvas context")
                return;
            }

            const chart = new Chart(ctx, {
                type: 'bar',
                plugins: [ChartDataLabels],
                data: {
                    labels: labels,
                    datasets: dataSet
                },
                options: {
                    responsive: true,
                    devicePixelRatio: 2,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        datalabels: {
                            align: 'center',
                            display: function (context: any) {
                                return context.dataset.data[context.dataIndex] != 0
                            },
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    },
                                    color: 'white'
                                }
                            }
                        }
                    },
                    animation: {
                        onComplete: function (animation) {
                            const base64 = chart.toBase64Image('image/png');
                            document.body.removeChild(containerDiv)
                            resolve(base64);
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Rating',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            },
                            title: {
                                display: true,
                                text: 'Count',
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                    }
                }
            })
        })
    }
}